import fetch from 'common/fetch';

// 商品管理接口
export function getProductList(params) {
  return fetch({
    url: '/system/info/list',
    method: 'post',
    data:params
  });
}

// 商品删除
export function Delete(data) {
  return fetch({
    url: '/system/info/remove',
    method: 'post',
    data
  });
}

// 合同查看信息
export function getView(params) {
  return fetch({
    url: '/system/info/getSkuPoolRelationList?skuId=' + params,
    method: 'get'
  });
}
// 商品品牌查询
export function getAllBrand(data) {
  return fetch({
    url: '/system/info/getAllBrand',
    method: 'post',
    data
  });
}
// 商品信息导出
export function Export(params) {
  return fetch({
    url: '/system/info/export',
    method: 'get',
    params,
    responseType: 'blob'
  });
}
// 添加下载模板
export function download(params) {
  return fetch({
    url: '/system/ware/file/downloadTarget?fileName=' + params,
    method: 'get',
    responseType: 'blob'
  });
}
// 源文件下载
export function downloadTarget(params) {
  return fetch({
    url: '/batch/order/oss/file/downloadTarget?fileName=' + params,
    method: 'get',
    responseType: 'blob'
  });
}
// 商品ProductPoolNo添加逗号隔开接口
export function ProductPoolNo(data) {
  return fetch({
    url: '/system/info/checkSuIdIOk',
    method: 'post',
    data
  });
}
// 根据skuIds添加多个商品，多个英文逗号隔开
export function addSkuByIds(data) {
  return fetch({
    url: '/system/sku/task/addSkuByIds',
    method: 'post',
    data
  });
}
// http://10.183.26.161/system/pool/getCategoryTree?userAgent=1&poolId=100000033
//查询商品信息列表
export function getBannerList(params) {
  return fetch({
    url: '/system/info/getCategoryAndParentVOList',
    method: 'get',
    params
  });
}
//通过合同号和skuids删除Commodity pools对应的商品—直接删除
///system/sku/pool
export function removePoolAndSkuRelations(data) {
  return fetch({
    url: '/system/sku/pool/removePoolAndSkuRelation',
    method: 'post',
    data
  });
}
// 商品管理add模块接口查看日志
export function getAddsList(params) {
  return fetch({
    url: '/system/sku/task/list',
    method: 'post',
    data: params
  });
}
// 登录接口没有用只是调用一下传一下参数
export function addLogo(params) {
  return fetch({
    url: '/system/sku/task/list?sso_service_ticket=' + params,
    method: 'get'
  });
}
//给Commodity pools添加商品 校验，添加  poolIds,skuIds, loginUser—页面直接添加
export function addPoolSkuId(data) {
  return fetch({
    url: '/system/sku/pool/addPoolSkuIds',
    method: 'POST',
    data
  });
}

// // 上传添加商品任务
// export function addPoolSkuId(data) {
//   return fetch({
//     url: '/system/sku/pool/addPoolSkuIds',
//     method: 'POST',
//     data
//   });
// }
// 商品管理接口结束
