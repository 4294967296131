<template>
  <div class="commodityDialog">
    <!-- Add to Product Poll -->
    <el-button size="mini" class="el-icon-plus addPoll" @click="addPoll">
      <!-- dialogFormVisible = true -->
      Add to product pool
    </el-button>
    <!-- Add to Product Poll-dialog -->
    <el-dialog
      title="Sku Batch Add Product Pool"
      :before-close="handleClose"
      :visible.sync="dialogFormVisible"
    >
      <el-form label-position="top">
        <el-form-item label="Selected Sku List">
          <textarea
            v-model.trim="form.selection"
            class="textarea"
            disabled="true"
          />
        </el-form-item>
      </el-form>
      <el-form label-position="top">
        <el-form-item label="Product Pool NO">
          <textarea
            v-model.trim="pool"
            class="textarea"
            placeholder="Separate multiple inputs with commas. You can aslo input this by 'Fill NO. From Excel'button below"
          />
        </el-form-item>
      </el-form>
      <div class="addButton">
        <el-upload
          class="upload-demo"
          :action="http"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-success="handleAvatarSuccess"
          multiple
          :limit="1"
          style="width:50%;"
        >
          <el-button size="small" type="primary">Fill NO From Excel</el-button>
        </el-upload>
        <el-button size="mini" class=" ExcelTemplate" @click="FromTemplate">
          Export Excel Template
        </el-button>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">
          Submit
        </el-button>
        <el-button @click="onCancel">
          Cancel
        </el-button>
      </div>
    </el-dialog>

    <!-- DELETE 弹窗-->
    <el-button
      size="mini"
      class="BatchDelete"
      icon="el-icon-delete"
      @click="Deletes"
    >
      Batch Delete
    </el-button>

    <el-button
      class="el-icon-plus AddProduct"
      type="primary"
      size="mini"
      style="margin-right: 40px"
      @click="AddProduct"
    >
      Add Product
    </el-button>

    <el-dialog
      title="Are you sure you want to delete the data?"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <span style="background:#FC9933">
        warning, the operation has risk, please be careful</span>
      <p style="font-weight:bold">
        if you delete the sku, the same sku in pool will be deleted too,maybe
        the customerwho has already add this sku can not buy it,
      </p>
      <p>are you sure to operate?</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">confirm</el-button>
        <el-button @click="cancel">cancel</el-button>
      </span>
    </el-dialog>

    <!-- Export Data -->
    <el-button
      size="mini"
      class="exports"
      icon="el-icon-download"
      @click="Exports"
    >
      Export Data
    </el-button>
  </div>
</template>

<script>
import { Delete, download, addPoolSkuId } from '@/api/VspProductApi/api';
import { mapState } from 'vuex';
export default {
  components: {},
  props: ['dates'],
  data() {
    return {
      dialogVisible: false,
      dialogVisibleExport: false,
      dialogVisibleDelete: false,
      input: '',
      dialogFormVisible: false,
      form: {
        // 选中的skuids
        selection: []
      },
      pool: null,
      exportData: null,
      skuIds: [],
      http:
        process.env.VUE_APP_BASE_API + 'system/sku/pool/batchAddPoolSkuIdsFile',
      excel: 'poolTemplat.xlsx'
      // openIsDisabled: true //没用选中的时候禁用删除按钮
    };
  },
  computed: {},
  watch: {
    checkId() {
      this.selection = this.$store.state.checkId;
    }
  },
  created() {},
  mounted() {},
  methods: {
    ...mapState(['checkId']),
    // 删除提示框
    handleClose(done) {
      this.$confirm('Confirm the shut down')
        .then(_ => {
          this.pool = null;
          this.form.selection = null;
          done();
        })
        .catch(_ => {});
    },
    AddProduct() {
      this.$router.push('/Manage/add');
    },
    //文件上传
    handleAvatarSuccess(res) {
      const number = res.data.map(item => {
        return item.skuId;
      });
      let numbers = number.join();
      this.pool = numbers;
    },
    handleRemove(file, fileList) {},
    handlePreview(file) {},
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    FromExcel() {},
    // 模板下载
    FromTemplate() {
      download(this.excel).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = this.excel;
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    // 导出商品信息
    Exports() {
      this.$emit('Export', 'pool');
    },
    addPoll() {
      if(this.$store.state.checkId && this.$store.state.checkId.length>0){
        this.form.selection = [];
        this.$store.state.checkId.forEach(item => {
          this.form.selection.push(String(item.skuId));
        });
        this.dialogFormVisible = true;
      } else {
        this.$message({
          message: 'Please select the item to added'
        })
      }
      
      
    },
    onSubmit() {
      var pool = null;
      if (this.pool) {
        pool = this.pool.split(',');
        var selection = this.form.selection.map(function(item, index, array) {
          return item - 0;
        });
        pool = pool.map(function(item, index, array) {
          return item - 0;
        });
      } else if (this.pool === null) {
        this.$message({
          message: 'Product Pool NO cannot be empty ',
          type: 'error'
        });
        return false;
      }
      addPoolSkuId(
        Object.assign({
          skuIds: selection,
          poolIds: pool,
        })
      ).then(res => {
        if (res.code != 200) {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        } else {
          this.$message({
            message: 'Operation succeeded',
            type: 'success'
          });
        }
      });
      this.dialogFormVisible = false;
      this.pool = null;
      this.form.selection = null;
    },
    onCancel() {
      this.pool = null;
      this.form.selection = null;
      this.dialogFormVisible = false;
    },
    // 删除商品接口
    Deletes() {
      if(this.$store.state.checkId && this.$store.state.checkId.length>0){
        this.dialogVisible = true;
      } else {
        this.$message({
          message: 'Please select the item to delete'
        })
      }
      
    },
    confirm() {
      const arry = {
        skuIds: [],
      };
      this.$store.state.checkId.forEach(item => {
        this.skuIds.push(item.skuId);
        arry.skuIds.push(item.skuId);
      });
      Delete(arry).then(async res => {
        if (res.code == 200) {
          await this.$message({
            message: 'successfully delete ',
            type: 'success'
          });
        } else {
          await this.$message({
            message: res.msg,
            type: 'error'
          });
          
        }
      });
      this.$emit('Refresh', 1);
      this.dialogVisible = false;
    },
    cancel() {
      this.dialogVisible = false;
      this.pool = null;
      // this.$store.state.checkId = null;
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.commodityDialog {
  .addPoll {
    margin-bottom: 20px;
  }
  .textarea {
    max-height: 177px;
    min-height: 177px;
    max-width: 100%;
    min-width: 100%;
    outline: none;
  }
  .dialog-footer > .el-button {
    width: 100px;
    height: 35px;
    padding: 0 !important;
  }
  /deep/.el-dialog__header {
    border-bottom: 1px solid black !important;
  }
  .BatchDelete {
    margin: 0 0 20px 8px;
  }
  .AddProduct {
    margin-bottom: 20px;
    float: right;
  }
  .exports {
    margin-bottom: 20px;
    margin-left: 10px;
  }
  .ExcelTemplate {
    margin-top: 20px;
  }
}
</style>
